// MIXINS
@mixin w-h($w: 50px, $h: 50px) {
  width: $w;
  height: $h;
}

@mixin flexbox($fd: row, $ai: center, $jc: center) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
}

body {
  background-color: black;
}

.timer {
  @include w-h(200px, 50px);
  font-size: 50px;
  color: white;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  letter-spacing: 5px;
}

.start,
.stop {
  @include w-h(150px, 50px);
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  font-size: 30px;
  color: white;
  border: solid 3px white;
  cursor: pointer;
}

@media (min-height: 800px) and (min-width: 800px) {
  .tile-container {
    @include w-h(600px, 600px);
  }
}

@media (max-height: 799px) and (min-height: 600px) {
  .tile-container {
    @include w-h(400px, 400px);
  }
}

@media (max-width: 799px) and (min-width: 600px) {
  .tile-container {
    @include w-h(400px, 400px);
  }
}

@media (min-height: 600px) {
  .tile {
    font-size: 70px;
  }

  .rules-dropdown {
    @include w-h(500px, 500px);
    font-size: 20px;

    img {
      @include w-h(200px, 200px);
    }
  }
}

@media (max-height: 599px) {
  .tile-container {
    @include w-h(300px, 300px);
  }

  .tile {
    font-size: 50px;
  }

  .rules-dropdown {
    @include w-h(300px, 300px);
    font-size: 10px;

    img {
      @include w-h(100px, 100px);
    }
  }
}

@media (max-width: 599px) {
  .tile-container {
    @include w-h(300px, 300px);
  }

  .tile {
    font-size: 50px;
  }

  .rules-dropdown {
    @include w-h(300px, 300px);
    font-size: 10px;

    img {
      @include w-h(100px, 100px);
    }
  }
}

.tile-container {
  border: solid 5px white;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 5px;
  row-gap: 5px;
}

.tile {
  @include w-h(100%, 100%);
  @include flexbox($ai: center, $jc: center);
  border: solid 5px transparent;
  background-color: black;
  color: white;
  cursor: pointer;
  user-select: none;
}

.blank-tile {
  @include w-h(100%, 100%);
  border: solid 5px transparent;
  background-color: white;
}

.hidden {
  color: black;
}

.disabled {
  opacity: 0.2;
  cursor: default;
}

.rules {
  @include w-h(30px, 30px);
  border: solid 2px white;
  border-radius: 50%;
  object-fit: contain;
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.rules-dropdown {
  @include flexbox($fd: column, $jc: space-evenly);
  border: solid 2px white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 10px;
  background-color: black;
  z-index: 10;
  cursor: default;

  ul {
    list-style: none;
  }

  div {
    justify-self: center;

    img {
      // @include w-h(200px, 200px);
      object-fit: contain;
      margin: 10px;
    }
  }
}

.overlay {
  @include w-h(100%, 100%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.win {
  @include w-h(350px, 100px);
  @include flexbox;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: solid 2px black;
  color: black;
  font-size: 30px;
  font-weight: 900;
  user-select: none;
  z-index: 3;
}
